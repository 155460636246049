<template>
  <Layout class="delete-account">
    <PageWrapper>
      <div class="delete-account-container">
        <h1>{{ $t("deleteAccount") }}</h1>
        <MarkdownRenderer :file="file"></MarkdownRenderer>
      </div>
    </PageWrapper>
  </Layout>
</template>

<script>
import Layout from '@/components/Layout/Layout'
import MarkdownRenderer from '@/components/MarkdownRenderer/MarkdownRenderer.vue'
import PageWrapper from '@/components/PageWrapper/PageWrapper'

export default {
  computed: {
    file() {
      return `/docs/delete-account-rules_${this.$i18n.locale}.md`
    },
  },
  components: {
    Layout,
    MarkdownRenderer,
    PageWrapper,
  },
}
</script>

<style lang="scss" scoped>
@import "@/css/base.scss";

.delete-account {
  .delete-account-container {
    color: $primaryColor;

    h1 {
      text-align: center;
      font-size: $fifty;
    }
  }
}
</style>
